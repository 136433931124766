
import { defineComponent, onMounted, ref, useAttrs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import { MenuComponent } from "@/assets/ts/components";
import { NumberOrString } from "@/core/directive/type/common";
import Swal from "sweetalert2";
import mixin from "@/mixins";
import {
  KycType,
  CardComponentsType,
  KycStatus,
} from "@/core/directive/type/kyc";
import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import { ApiKyc } from "@/core/api";
import { IPagination } from "@/core/directive/interface/common";

import {
  ContactInfo,
  ContactInfoData,
  LegalInfo,
  legalInfoData,
  BillingAddressInfo,
  billingAddressInfoData,
  WarehouseInfo,
  warehouseInfoData,
  InventoryInfo,
  inventoryInfoData,
  FinancialInfo,
  financialInfoData,
  TaxInfo,
  taxInfoData,
  SettlementEntityOverview,
  settlementEntityOverviewData,
} from "@/core/directive/interface/kyc";

import ContactCard from "@/components/page/kyc/common/ContactCard.vue";
import RegistrationCard from "@/components/page/kyc/common/RegistrationCard.vue";
import BillingCard from "@/components/page/kyc/common/BillingCard.vue";
import WarehouseCard from "@/components/page/kyc/common/WarehouseCard.vue";
import InventoryCard from "@/components/page/kyc/common/InventoryCard.vue";
import FinancialCard from "@/components/page/kyc/common/FinancialCard.vue";
import TaxCard from "@/components/page/kyc/common/TaxCard.vue";
import ContactEditModal from "@/components/page/kyc/common/ContactEditModal.vue";
import RegistrationEditModal from "@/components/page/kyc/common/RegistrationEditModal.vue";
import BillingEditModal from "@/components/page/kyc/common/BillingEditModal.vue";
import WarehouseEditModal from "@/components/page/kyc/common/WarehouseEditModal.vue";
import InventoryEditModal from "@/components/page/kyc/common/InventoryEditModal.vue";
import FinancialEditModal from "@/components/page/kyc/common/FinancialEditModal.vue";
import TaxEditModal from "@/components/page/kyc/common/TaxEditModal.vue";

import EmptyData from "@/components/layout/EmptyData.vue";

import _ from "lodash";

export default defineComponent({
  components: {
    ContactCard,
    RegistrationCard,
    BillingCard,
    WarehouseCard,
    InventoryCard,
    FinancialCard,
    TaxCard,
    ContactEditModal,
    RegistrationEditModal,
    BillingEditModal,
    WarehouseEditModal,
    InventoryEditModal,
    FinancialEditModal,
    TaxEditModal,
    EmptyData,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref(false);
    const { showServerErrorMsg, showFormSubmitSuccessMsg } = mixin();

    const baseData = ref(
      Object.assign({}, _.cloneDeep(settlementEntityOverviewData))
    );

    const applicationScenarios =
      getKycCommonTypeParams.value.applicationScenarios;
    const settlementEntityId = route.params.id;

    const contactView = ref<ContactInfo>(ContactInfoData);
    const registrationView = ref<LegalInfo>(legalInfoData);
    const billingList = ref<BillingAddressInfo[]>([billingAddressInfoData]);
    const warehouseList = ref<WarehouseInfo[]>([warehouseInfoData]);
    const inventoryList = ref<InventoryInfo[]>([inventoryInfoData]);
    const financialList = ref<FinancialInfo[]>([financialInfoData]);
    const taxList = ref<TaxInfo[]>([taxInfoData]);

    const contactEditModal = ref();
    const contactEditId = ref<NumberOrString>(0);
    const registrationEditModal = ref();
    const billingEditModal = ref();
    const billingEditId = ref<NumberOrString>(0);
    const registrationEditId = ref<NumberOrString>(0);
    const warehouseEditModal = ref();
    const warehouseEditId = ref<NumberOrString>(0);
    const inventoryEditModal = ref();
    const inventoryEditId = ref<NumberOrString>(0);
    const financialEditModal = ref();
    const financialEditId = ref<NumberOrString>(0);
    const taxEditModal = ref();
    const taxEditId = ref<NumberOrString>(0);

    const cardComponentsLoading = ref({
      [CardComponentsType.Contact]: false,
      [CardComponentsType.Registration]: false,
      [CardComponentsType.Billing]: false,
      [CardComponentsType.Warehouse]: false,
      [CardComponentsType.Inventory]: false,
      [CardComponentsType.Financial]: false,
      [CardComponentsType.Tax]: false,
    });

    const updateSection = ref({
      contact: "identification",
      registration: "identification",
      warehouse: "identification",
      inventory: "identification",
      financial: "identification",
    });

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as SettlementEntityOverview;
        baseData.value = base;
      },
      { immediate: true }
    );

    /**
     * 页面控制
     */

    // 分页
    const billingPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const warehousePagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const inventoryPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const financialPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const taxPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });

    // 按钮显示
    const buttonShowControl = (item?: any, length?: any) => {
      const buttonShow = {
        add: false,
        edit: false,
        deactivate: false,
      };
      if (item) {
        if (item.identification.status === KycStatus.Activated) {
          buttonShow.edit = true;
          buttonShow.deactivate = true;
        } else if (item.identification.status === KycStatus.Deactivated) {
          buttonShow.edit = true;
        }
      }
      if (!length || length < 10) {
        buttonShow.add = true;
      }
      return buttonShow;
    };

    const isNullData = (params: any) => {
      let isNull =
        params === null ||
        params === undefined ||
        JSON.stringify(params) === "[]" ||
        JSON.stringify(params) === "{}" ||
        JSON.stringify(params) === `""`;
      return isNull;
    };

    /**
     * 请求数据
     */

    // 联系信息
    const getContactStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Contact] = true;
      const { data } =
        await getKycCommonTypeParams.value.getContactStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Contact] = false;
      if (data.code === 0) {
        contactView.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 注册信息
    const getRegistrationStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Registration] = true;
      const { data } =
        await getKycCommonTypeParams.value.getRegistrationStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Registration] = false;
      if (data.code === 0) {
        registrationView.value = data.data;
      } else {
        // registrationView.value = [];
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 账单信息
    const getBillingList = async () => {
      cardComponentsLoading.value[CardComponentsType.Billing] = true;
      const { data } = await ApiKyc.getBillingList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: billingPagination.value.page,
        page_size: billingPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Billing] = false;
      if (data.code === 0) {
        billingList.value.splice(
          0,
          billingList.value.length,
          ...data.data.items
        );
        billingPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 仓库信息
    const getWarehouseList = async () => {
      cardComponentsLoading.value[CardComponentsType.Warehouse] = true;
      const { data } = await ApiKyc.getWarehouseList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: warehousePagination.value.page,
        page_size: warehousePagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Warehouse] = false;
      if (data.code === 0) {
        warehouseList.value.splice(
          0,
          warehouseList.value.length,
          ...data.data.items
        );
        warehousePagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 库存信息
    const getInventoryList = async () => {
      cardComponentsLoading.value[CardComponentsType.Inventory] = true;
      const { data } = await ApiKyc.getInventoryList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: inventoryPagination.value.page,
        page_size: inventoryPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Inventory] = false;
      if (data.code === 0) {
        inventoryList.value.splice(
          0,
          inventoryList.value.length,
          ...data.data.items
        );
        inventoryPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 财务信息
    const getFinancialList = async () => {
      cardComponentsLoading.value[CardComponentsType.Financial] = true;
      const { data } = await ApiKyc.getFinancialList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: financialPagination.value.page,
        page_size: financialPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Financial] = false;
      if (data.code === 0) {
        financialList.value.splice(
          0,
          financialList.value.length,
          ...data.data.items
        );
        financialPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 税务信息
    const getTaxList = async () => {
      cardComponentsLoading.value[CardComponentsType.Tax] = true;
      const { data } = await ApiKyc.getTaxList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: taxPagination.value.page,
        page_size: taxPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Tax] = false;
      if (data.code === 0) {
        taxList.value.splice(0, taxList.value.length, ...data.data.items);
        taxPagination.value.total == data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };

    const getAllList = async () => {
      loading.value = true;
      Promise.all([
        getContactStateAddress(),
        getRegistrationStateAddress(),
        getBillingList(),
        getWarehouseList(),
        getInventoryList(),
        getFinancialList(),
        getTaxList(),
      ])
        .then(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /**
     * 编辑数据
     */
    const alertUpdateTips = (target: CardComponentsType, callback) => {
      let onlyOneAddress = true;
      let tips = "";
      if (target === CardComponentsType.Billing) {
        onlyOneAddress = billingList.value.length === 1;
      } else if (target === CardComponentsType.Warehouse) {
        onlyOneAddress = warehouseList.value.length === 1;
      } else if (target === CardComponentsType.Inventory) {
        onlyOneAddress = inventoryList.value.length === 1;
      } else if (target === CardComponentsType.Financial) {
        onlyOneAddress = financialList.value.length === 1;
      } else if (target === CardComponentsType.Tax) {
        onlyOneAddress = taxList.value.length === 1;
      }
      if (onlyOneAddress) {
        tips = t("kyc.tips.settlementEntityOnlyOneAddressEditTips");
      } else {
        tips = t("kyc.tips.settlementEntityMultipleAddressEditTips");
      }
      Swal.fire({
        text: tips,
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-body",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          callback();
        }
      });
    };

    // 联系信息
    const editContactIdentification = (id: NumberOrString) => {
      updateSection.value.contact = "identification";
      contactEditId.value = id;
      contactEditModal.value?.modalShow();
    };
    const editContactAdditional = (id: NumberOrString) => {
      updateSection.value.contact = "additional";
      contactEditId.value = id;
      contactEditModal.value?.modalShow();
    };

    // 注册信息
    const addRegistration = () => {
      registrationEditId.value = 0;
      registrationEditModal.value?.modalShow();
    };
    const editRegistrationIdentification = (id: NumberOrString) => {
      updateSection.value.registration = "identification";
      registrationEditId.value = id;
      registrationEditModal.value?.modalShow();
    };
    const editRegistrationAdditional = (id: NumberOrString) => {
      updateSection.value.registration = "additional";
      registrationEditId.value = id;
      registrationEditModal.value?.modalShow();
    };

    // 账单信息
    const addBilling = () => {
      billingEditId.value = 0;
      billingEditModal.value?.modalShow();
    };
    const editBilling = (id: NumberOrString) => {
      billingEditId.value = id;
      alertUpdateTips(CardComponentsType.Billing, () => {
        billingEditModal.value?.modalShow();
      });
    };

    // 仓库信息
    const addWarehouse = () => {
      warehouseEditId.value = 0;
      warehouseEditModal.value?.modalShow();
    };
    const editWarehouseIdentification = (id: NumberOrString) => {
      updateSection.value.warehouse = "identification";
      warehouseEditId.value = id;
      alertUpdateTips(CardComponentsType.Warehouse, () => {
        warehouseEditModal.value?.modalShow();
      });
    };
    const editWarehouseAdditional = (id: NumberOrString) => {
      updateSection.value.warehouse = "additional";
      warehouseEditId.value = id;
      alertUpdateTips(CardComponentsType.Warehouse, () => {
        warehouseEditModal.value?.modalShow();
      });
    };

    // 库存信息
    const addInventory = () => {
      inventoryEditId.value = 0;
      inventoryEditModal.value?.modalShow();
    };
    const editInventoryIdentification = (id: NumberOrString) => {
      updateSection.value.inventory = "identification";
      inventoryEditId.value = id;
      alertUpdateTips(CardComponentsType.Inventory, () => {
        inventoryEditModal.value?.modalShow();
      });
    };
    const editInventoryAdditional = (id: NumberOrString) => {
      updateSection.value.inventory = "additional";
      inventoryEditId.value = id;
      alertUpdateTips(CardComponentsType.Inventory, () => {
        inventoryEditModal.value?.modalShow();
      });
    };

    // 财务信息
    const addFinancial = () => {
      financialEditId.value = 0;
      financialEditModal.value?.modalShow();
    };
    const editFinancialIdentification = (id: NumberOrString) => {
      updateSection.value.financial = "identification";
      financialEditId.value = id;
      alertUpdateTips(CardComponentsType.Financial, () => {
        financialEditModal.value?.modalShow();
      });
    };
    const editFinancialAdditional = (id: NumberOrString) => {
      updateSection.value.financial = "additional";
      financialEditId.value = id;
      alertUpdateTips(CardComponentsType.Financial, () => {
        financialEditModal.value?.modalShow();
      });
    };

    // 税务信息
    const addTax = () => {
      taxEditId.value = 0;
      taxEditModal.value?.modalShow();
    };
    const editTax = (id: NumberOrString) => {
      taxEditId.value = id;
      alertUpdateTips(CardComponentsType.Tax, () => {
        taxEditModal.value?.modalShow();
      });
    };

    /**
     * 停用数据
     */
    const onDeactivateButtonClick = (
      refreshTarget: CardComponentsType,
      id: NumberOrString
    ) => {
      Swal.fire({
        text: t("kyc.tips.settlementEntityDeactivateTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-body",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await ApiKyc.changeAddressStatus({
            id: id,
            status: KycStatus.Deactivated,
          });
          if (data.code === 0) {
            showFormSubmitSuccessMsg(() => {
              updateCardInfo(refreshTarget);
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    /**
     * 更新指定模块信息
     */

    const updateCardInfo = (type: CardComponentsType) => {
      if (type === CardComponentsType.Contact) {
        getContactStateAddress();
      } else if (type === CardComponentsType.Registration) {
        getRegistrationStateAddress();
      } else if (type == CardComponentsType.Billing) {
        getBillingList();
      } else if (type === CardComponentsType.Warehouse) {
        getWarehouseList();
      } else if (type == CardComponentsType.Inventory) {
        getInventoryList();
      } else if (type === CardComponentsType.Financial) {
        getFinancialList();
      } else if (type === CardComponentsType.Tax) {
        getTaxList();
      }
    };

    const reinitMenuComponent = () => {
      MenuComponent.reinitialization();
    };

    onMounted(() => {
      getAllList();
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      loading,
      cardComponentsLoading,
      baseData,
      getKycCommonTypeParams,
      KycType,
      CardComponentsType,
      updateCardInfo,
      //请求数据
      contactView,
      registrationView,
      billingList,
      warehouseList,
      inventoryList,
      financialList,
      taxList,
      //分页
      billingPagination,
      warehousePagination,
      inventoryPagination,
      financialPagination,
      taxPagination,
      //按钮显示
      buttonShowControl,
      isNullData,
      //编辑数据
      contactEditModal,
      contactEditId,
      editContactIdentification,
      editContactAdditional,
      registrationEditModal,
      registrationEditId,
      addRegistration,
      editRegistrationIdentification,
      editRegistrationAdditional,
      billingEditModal,
      billingEditId,
      addBilling,
      editBilling,
      warehouseEditModal,
      warehouseEditId,
      addWarehouse,
      editWarehouseIdentification,
      editWarehouseAdditional,
      inventoryEditModal,
      inventoryEditId,
      addInventory,
      editInventoryIdentification,
      editInventoryAdditional,
      financialEditModal,
      financialEditId,
      addFinancial,
      editFinancialIdentification,
      editFinancialAdditional,
      taxEditModal,
      taxEditId,
      addTax,
      editTax,

      // 停用数据
      onDeactivateButtonClick,
      updateSection,
    };
  },
});
